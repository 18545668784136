/* Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
A copy of the License is located at

    http://www.apache.org/licenses/LICENSE-2.0

or in the "license" file accompanying this file. This file is distributed
on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
express or implied. See the License for the specific language governing
permissions and limitations under the License. */

@font-face {
	font-family: 'AkkoPro Light';
	src: url('./assets/Fonts/AkkoPro-Light.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'AkkoPro Medium';
	src: url('./assets/Fonts/AkkoPro-Medium.otf');
	font-weight: normal;
	font-style: normal;
}

body {
	margin: 0;
	font-family: 'AkkoPro Medium', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/*
 * See full list: https://docs.amplify.aws/ui/customization/theming/q/framework/react#supported-css-custom-properties
 */
:root {
	--amplify-primary-color: #ff5000;
	--amplify-secondary-color: #263238;
	--amplify-primary-tint: #ff9800;
	--amplify-light-grey: #dadfe4;
	--amplify-font-family: 'AkkoPro Medium', sans-serif;
}

/*
 * See full list: https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#props-css-amplify-authenticator
 */
amplify-authenticator {
	--border-radius: 3px;
	--box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.15);
	--padding: 0px 16px 0px 16px;
	--margin-bottom: 0px;
	--container-display: flex;
	--container-justify: center;
	--container-align: center;
	--container-height: auto;
}

input,
.btn {
	width: 100%;
	padding: 12px;
	opacity: 1;
	border: none;
	border-radius: 3px;
	margin: 5px 0;
	display: inline-block;
	font-size: 17px;
	line-height: 20px;
	text-decoration: none;
}

input:hover,
.btn:hover {
	opacity: 0.7;
	cursor: pointer;
}

.fb,
.amazon,
.saml,
.google {
	background-color: #ffffff;
	border: 1px solid #dadfe4;
	font-family: 'AkkoPro Light';
	color: black;
}

#languageSelect,
.MuiMenuItem-root {
	font-family: 'AkkoPro Light' !important;
}
.MuiSelect-select:focus {
	background-color: transparent !important;
}
.MuiInput-underline:before {
	border-color: #00547b !important;
}
.MuiSelect-icon {
	color: #00547b !important;
}

.registrationButton {
	cursor: pointer;
	margin: 0.5rem;
	border: none;
	font-size: var(--amplify-text-sm);
	font-family: var(--amplify-font-family);
	background-color: var(--amplify-primary-color);
	padding: 1rem;
	color: #fff;
	min-width: 153px;
	display: 'inline-block';
	font-weight: 900;
}

.registrationButton:hover {
	opacity: 0.8;
}
